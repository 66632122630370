import { createColumnHelper, Table as TableType } from "@tanstack/react-table";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { IState } from "app/store";
import { PaymentColumns } from "types";

import { Button } from "components/Button";
import { Pill } from "components/Pill";
import { Table } from "components/Table";

import { formatCurrencyWithNumberFormat } from "lib/formatNumber";
import { splitWordOnNewLine } from "lib/helpers";
import { useGetHandlerExport } from "lib/hooks/useGetHandleExport";
import {
  useGetAllPaymentsQuery,
  useGetMatchedPaymentsQuery
} from "lib/slices/presentationServiceAPISlice";

import { countryCodeMapper } from "utils/i18n";

import { StatusBadge } from "pages/Payments/components/StatusBadge";
import { Truncate } from "pages/Payments/components/Truncate";

import styles from "../../PagePayments.module.scss";

type Props = {
  type: "all" | "matched";
};

const columnHelper = createColumnHelper<PaymentColumns>();

export function AllPayments({ type }: Props) {
  const { t } = useTranslation();
  const language = useSelector((state: IState) => state.language.language);
  const tableRef = useRef<TableType<any> | null>(null);

  const { data: allPayments, isLoading: isLoadingAllPayments } = useGetAllPaymentsQuery();
  const { data: matchedPayments, isLoading: isLoadingMatchedPayments } = useGetMatchedPaymentsQuery();


  const { exporter, exportStatus } = useGetHandlerExport({
    retrieveSelected: false,
    type
  });

  const handleExport = () => {
    exporter(tableRef.current ?? undefined);
  };

  // Columns for the table
  const allPaymentsColumns = [
    columnHelper.accessor("customerId", {
      header: t("pages.payments.columnHeaders.customerAndId"),
      cell: (info) => {
        return (
          <div className={styles.alignContentToLeft}>
            <p>
              {info.cell.row.original.customerName ||
                t("pages.payments.missingValue")}
            </p>
            {info.getValue() && (
              <Pill
                text={`${info.getValue()}`}
                href={`/customer/${info.getValue()}`}
                intent="Link"
              />
            )}
          </div>
        );
      },
      meta: {
        headerIcon: "usercirclesingle",
      },
    }),
    columnHelper.accessor("customerName", {
      meta: {
        hidden: true
      }
    }),
    columnHelper.accessor("inioInvoiceNo", {
      header: t("pages.payments.columnHeaders.caseId"),
      cell: (info) => (
        <div className={styles.alignContentToLeft}>
          <Pill
            text={`${info.getValue()}`}
            href={`/cases/${info.getValue()}`}
            intent="Link"
          />
        </div>
      ),
      meta: {
        headerIcon: "streamlineCoreLineFileText",
      },
    }),
    columnHelper.accessor("status", {
      header: t("pages.payments.columnHeaders.status"),
      cell: (info) => (
        <div className={styles.statusBadgesContainer}>
            <StatusBadge
              key={info.getValue()}
              status={info.getValue().split("_").join(" ") as PaymentColumns["status"]}
            />
            {info.cell.row.original.statusReason && 
              <StatusBadge 
                key={info.cell.row.original.statusReason}
                status={info.cell.row.original.statusReason.split("_").join(" ") as PaymentColumns["statusReason"]}
              />
            }
        </div>
      ),
      meta: {
        headerIcon: "streamLineFileCheck",
      },
    }),
    columnHelper.accessor("amount", {
      header: t("pages.payments.columnHeaders.amount"),
      cell: (info) => {
        return formatCurrencyWithNumberFormat(
          info.getValue(),
          language,
          info.cell.row.original.currency,
        );
      },
      meta: {
        filterType: "text",
        headerIcon: "coreLineMoney",
      },
    }),
    columnHelper.accessor("paidDate", {
      header: t("pages.payments.columnHeaders.paymentDate"),
      cell: (info) => {
        return `${new Intl.DateTimeFormat(
          countryCodeMapper[language as keyof typeof countryCodeMapper],
          {
            month: "long",
            day: "numeric",
            year: "numeric",
          },
        ).format(new Date(info.getValue()))}`;
      },
      meta: {
        headerIcon: "blankcalendar",
        filterType: "date",
        wrapHeader: true
      },
      filterFn: (row, columnId, filterValue) => {
        const date = new Date(row.getValue(columnId));
        const [start, end] = filterValue || [];
        return (
          (!start || date >= new Date(start)) && (!end || date <= new Date(end))
        );
      },
    }),
    columnHelper.accessor("transactionReference", {
      header: t("pages.payments.columnHeaders.transactionReference"),
      cell: (info) => <Truncate value={info.getValue()} />,
      meta: {
        headerIcon: "streamlineCoreLineFileText",
        wrapHeader: true
      },
    }),
    columnHelper.accessor("paymentReference", {
      header: t("pages.payments.columnHeaders.paymentReference"),
      cell: (info) =>
        info.getValue() ? (
          <>
            {splitWordOnNewLine(info.getValue() as string, 20).map((el) => (
              <p key={el}>{el}</p>
            ))}
          </>
        ) : (
          t("pages.payments.missingValue")
        ),
      meta: {
        headerIcon: "streamlineCoreLineFileText",
        wrapHeader: true,
      },
    }),
    columnHelper.accessor("transactionType", {
      header: t("pages.payments.columnHeaders.paymentMethod"),
      cell: (info) => info.getValue(),
      meta: {
        headerIcon: "bank",
        filterType: "checkboxes",
        filterOptions: [
          t("pages.payments.bankTransfer"),
          t("pages.payments.card"),
          t("pages.payments.swish"),
        ],
        wrapHeader: true
      },
      filterFn: (row, columnId, filterValue) => {
        const paymentMethod = row.getValue(columnId) as string;

        if (filterValue.length > 0) {
          return filterValue.includes(paymentMethod);
        }
      },
    }),
  ];

  return (
    <>
      <Table
        ref={tableRef}
        data={
          type === "all"
            ? allPayments?.rows
            : matchedPayments?.rows
        }
        columns={allPaymentsColumns}
        header={true}
        filters={true}
        searchPlaceholder={t("pages.payments.searchPlaceholder")}
        isLoading={type === "all" ? isLoadingAllPayments : isLoadingMatchedPayments}
        headerCta={
          <Button
            intent="black"
            text={t("pages.payments.export")}
            icon={{ name: "arrowup", intent: "white" }}
            iconPosition="right"
            className={styles.exportButton}
            onClick={handleExport}
            disabled={exportStatus.loading === "loading"}
          />
        }
        noDataAvailableText={
          type === "all"
            ? t("pages.payments.noAllPayments")
            : t("pages.payments.notMatchedPayments")
        }
      />
      {exportStatus.error && "Error"}
    </>
  );
}
