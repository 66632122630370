export enum QUERY_KEYS {
  sort = "sort",
  pagination = "page",
  order = "order",
  desc = "desc",
  asc = "asc",
}

export function resolveState<T>(
  updater: T | ((old: T) => T),
  currentState: T,
): T {
  if (typeof updater === "function") {
    return (updater as (old: T) => T)(currentState);
  }
  return updater;
}

export function updateUrlWithParams(
  queryParam: string,
  value: string | undefined,
) {
  const searchParams = new URLSearchParams(window.location.search);
  if (value === undefined) {
    searchParams.delete(queryParam);
  } else {
    searchParams.set(queryParam, value);
  }

  const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
  window.history.replaceState({}, "", newUrl);
}
