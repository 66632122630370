import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { HOST_PRESENTATION_SERVICE } from "config/common";
import { userManager } from "index";
import { PaymentColumns } from "types";

import { getStageStatus } from "lib/helpers";
import { appStorage } from "lib/localStorage";

const baseQuery = fetchBaseQuery({
  baseUrl: HOST_PRESENTATION_SERVICE,
  credentials: "include",
  prepareHeaders: (headers) => {
    const storageKey = `oidc.user:${process.env.REACT_APP_KEYCLOAK_AUTHORITY}/${process.env.REACT_APP_KEYCLOAK_REALM}:${process.env.REACT_APP_KEYCLOAK_CLIENT}`;
    const clientId = JSON.parse(sessionStorage.getItem(storageKey) ?? "{}")
      .profile?.clientId;

    const token = JSON.parse(
      sessionStorage.getItem(storageKey) ?? "{}",
    ).access_token;

    headers.set("Authorization", `Bearer ${token}`);
    clientId && headers.set("X-Client-Id", clientId);

    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    await userManager.signoutRedirect();
    // We could refresh the JWT token here in the future instead
    // of redirecting back to the /login page. Depends on our auth strategy.
  }
  return result;
};

export const presentationServiceAPISlice = createApi({
  reducerPath: "presentation-service-api",
  tagTypes: ["Clients", "Payments"],
  baseQuery: baseQueryWithReauth,
  serializeQueryArgs: ({ endpointName, queryArgs }) => {
    const clientId = appStorage.getItem<string>("clientId");
    const clientSuffix = clientId ? `-${clientId}` : "";

    if (typeof queryArgs === "string") {
      // If queryArgs is a string (e.g., an ID), include it in the cache key
      return `${endpointName}-${queryArgs}${clientSuffix}`;
    } else if (queryArgs && typeof queryArgs === "object") {
      // If queryArgs is an object, you can pick specific properties
      // For example, cache based on 'status' property if it exists
      const { status } = queryArgs;
      const querySuffix = status ? `-${status}` : "";
      return `${endpointName}${querySuffix}${clientSuffix}`;
    }
    // For endpoints without queryArgs, use the endpoint name and clientId as the cache key
    return `${endpointName}${clientSuffix}`;
  },
  endpoints: (builder) => ({
    // SA Routes
    getClients: builder.query<{ data: Client[]; totalResults: number }, void>({
      query: () => "/clients",
      providesTags: ["Clients"],
    }),
    getClient: builder.query<{ data: Client }, string>({
      query: (clientId) => `/clients/${clientId}`,
      providesTags: ["Clients"],
    }),
    addClient: builder.mutation<
      { id: string; slug: string; readableId: string; name: string },
      void
    >({
      query: (body) => ({
        url: "/clients",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Clients"],
    }),
    updateClient: builder.mutation<
      string,
      { id: string; body: Partial<Client> }
    >({
      query: ({ id, body }) => ({
        url: `/clients/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Clients"],
    }),
    deleteClient: builder.mutation({
      query: (id) => ({
        url: `/clients/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Clients"],
    }),

    // Customers endpoints
    getCustomers: builder.query<TableResponse<CustomerRes>, void>({
      query: () => "/customers?page=1&limit=5000",
    }),
    getCustomer: builder.query<SingleCustomerRes, string>({
      query: (id) => `/customers/${id}`,
    }),

    // Cases endpoints
    getCases: builder.query<TableResponse<CaseRes>, void>({
      query: () => "/cases?page=1&limit=5000",
      transformResponse: (res: TableResponse<CaseRes>) => {
        res.rows = res.rows.map((c) => ({
          ...c,
          dueDate: new Date(c.dueDate).toLocaleDateString(),
          status: getStageStatus(c.status).caseStatus,
          stage: getStageStatus(c.status).caseStage,
          trb: `${c.totalRemainingBalance} ${c.currency}`,
        }));
        return res;
      },
    }),
    getCaseById: builder.query<SingleCaseRes, string>({
      query: (id) => `/cases/${id}`,
    }),

    getMembers: builder.query<Member[], void>({
      query: () => "/members",
    }),
    createMember: builder.mutation({
      query: (body) => ({
        url: "/members",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Clients"],
    }),
    editMember: builder.mutation({
      query: (body) => ({
        url: `/members/${body.userId}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Clients"],
    }),
    deleteMember: builder.mutation({
      query: (body) => ({
        url: `/members/${body.id}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Clients"],
    }),
    // Payments endpoints
    getAllPayments: builder.query<TableResponse<PaymentColumns>, void>({
      query: () => "/payments?&limit=5000",
      providesTags: ["Payments"],
    }),
    getMatchedPayments: builder.query<TableResponse<PaymentColumns>, void>({
      query: () => "/payments/matched?limit=5000",
      providesTags: ["Payments"],
    }),
    getNotMatchedPayments: builder.query<TableResponse<PaymentColumns>, void>({
      query: () => "/payments/not-matched?limit=5000",
      providesTags: ["Payments"],
    }),
    getNotProcessedPayments: builder.query<TableResponse<PaymentColumns>, void>({
      query: () => "/payments/not-processed?limit=5000",
      providesTags: ["Payments"],
    }),
    updateStatusPayment: builder.mutation({
      query: (body) => ({
        url: "/payments/update-status",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Payments"],
    }),
    searchSpecificInvoices: builder.query<
      {
        rows: Pick<
          Case,
          | "id"
          | "customerName"
          | "dueDate"
          | "totalAmount"
          | "paymentReference"
          | "customerSSN"
        >[];
        totalItems: number;
      },
      string
    >({
      query: (url) => {
        return "/cases/search" + url;
      },
    }),
  }),
});

export const {
  useGetCustomerQuery,
  useGetCustomersQuery,
  useLazyGetCustomersQuery,
  useGetClientsQuery,
  useLazyGetCasesQuery,
  useAddClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useGetCaseByIdQuery,
  useGetCasesQuery,
  useGetMembersQuery,
  useCreateMemberMutation,
  useDeleteMemberMutation,
  useEditMemberMutation,
  useGetClientQuery,
  useGetAllPaymentsQuery,
  useGetMatchedPaymentsQuery,
  useGetNotProcessedPaymentsQuery,
  useGetNotMatchedPaymentsQuery,
  useUpdateStatusPaymentMutation,
  useSearchSpecificInvoicesQuery,
} = presentationServiceAPISlice;
