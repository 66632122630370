import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "react-oidc-context";
import { Provider } from "react-redux";

import { PROD } from "config/common";
import { UserManager } from "oidc-client-ts";

import "styles/main.scss";

import App from "./App";
import { store } from "./app/store";
import debugMenu from "./debugMenu";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

const oidcConfig = {
  authority: `${process.env.REACT_APP_KEYCLOAK_AUTHORITY}/${process.env.REACT_APP_KEYCLOAK_REALM}`,
  client_id: process.env.REACT_APP_KEYCLOAK_CLIENT as string,
  redirect_uri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI as string,
  response_type: "code",
  scope: "openid profile email",
  automaticSilentRenew: true,
};

export const userManager = new UserManager(oidcConfig);

const onSigninCallback = () => {
  const previousPath = sessionStorage.getItem("previousPath");
  if (previousPath) {
    sessionStorage.removeItem("previousPath");
    window.location.replace(previousPath);
  } else {
    window.history.replaceState({}, document.title, window.location.pathname);
  }
};

root.render(
  <StrictMode>
    <Provider store={store}>
      <AuthProvider
        {...oidcConfig}
        onSigninCallback={onSigninCallback}
        userManager={userManager}
      >
        <App />
      </AuthProvider>
    </Provider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// On non-prod enviroments, enable the debug menu commands
!PROD && debugMenu();
