import { createColumnHelper } from "@tanstack/react-table";

import { AuthRoleCheck } from "components/AuthRoleCheck";
import { Pill } from "components/Pill";

import { readableId } from "lib/helpers";

const columnHelper = createColumnHelper<CaseRes>();

export const caseColumns = [
  columnHelper.accessor("customerName", {
    header: "Name",
    cell: (info) => (
      <>
        {info.getValue()}
        <AuthRoleCheck role="client-operator">
          <p>
            <strong>{info.row.original.clientName}</strong>
          </p>
        </AuthRoleCheck>
      </>
    ),
    meta: {
      headerIcon: "usercirclesingle",
    },
  }),
  columnHelper.accessor("customerNo", {
    header: "Customer No",
    cell: (info) => (
      <Pill
        text={info.row.original.customerNo}
        intent="Link"
        href={`/customer/${info.row.original.customerId}`}
      />
    ),
    meta: {
      headerIcon: "userprofilefocus",
    },
  }),
  columnHelper.accessor("invoiceNo", {
    header: "Case ID",
    cell: (info) => (
      <Pill
        text={info.row.original.invoiceNo ?? readableId(info.row.original.id)}
        intent="Link"
        href={`/customer/${info.row.original.customerId}}/${info.row.original.id}`}
      />
    ),
    meta: {
      headerIcon: "streamlineCoreLineFileText",
    },
  }),
  columnHelper.accessor("countryCode", {
    header: "Country",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "earth",
    },
  }),
  columnHelper.accessor("dueDate", {
    header: "Due Date",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "blankcalendar",
    },
  }),
  columnHelper.accessor("trb", {
    header: "TRB",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("totalRemainingBalance", {
    header: "TRB",
    cell: (info) => info.getValue(),
    filterFn: "includesString",
    meta: {
      hidden: true,
      filterType: "text",
    },
  }),
  columnHelper.accessor("stage", {
    id: "stage",
    header: "Stage",
    cell: (info) => info.getValue(),
    filterFn: "arrIncludesSome",
    meta: {
      headerIcon: "stageClockHand",
      filterType: "checkboxes",
      filterOptions: ["Invoiced", "Debt Collection", "Reminded"],
    },
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: (info) => info.getValue(),
    filterFn: "arrIncludesSome",
    meta: {
      headerIcon: "streamLineFileCheck",
      filterType: "checkboxes",
      filterOptions: ["Active", "Cleared"],
    },
  }),
];
