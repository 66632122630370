import { createColumnHelper, Row, Table as TableType } from "@tanstack/react-table";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { IState } from "app/store";
import { PaymentColumns } from "types";

import { Button } from "components/Button";
import { ModalAction } from "components/Modal";
import { Pill } from "components/Pill";
import { Table } from "components/Table";

import { formatCurrencyWithNumberFormat } from "lib/formatNumber";
import { splitWordOnNewLine } from "lib/helpers";
import { useGetHandlerExport } from "lib/hooks/useGetHandleExport";
import {
  useGetNotProcessedPaymentsQuery,
  useUpdateStatusPaymentMutation,
} from "lib/slices/presentationServiceAPISlice";

import { countryCodeMapper } from "utils/i18n";

import { StatusBadge } from "pages/Payments/components/StatusBadge";

import styles from "../../PagePayments.module.scss";
import { Truncate } from "../../components/Truncate";
import { MatchPaymentsDetails } from "./MatchPaymentsDetails";

const columnHelper = createColumnHelper<PaymentColumns & { checkbox: any }>();

type Props = {
  moveToMatchedPaymentsTab: () => void;
};

export function NotProcessedPayments({ moveToMatchedPaymentsTab }: Props) {
  const { t } = useTranslation();
  const language = useSelector((state: IState) => state.language.language);
  const tableRef = useRef<TableType<any> | null>(null);

  const { data, isLoading } = useGetNotProcessedPaymentsQuery();
  const [updateStatusPayment, { isLoading: isChangeStatusLoading }] =
    useUpdateStatusPaymentMutation();

  const { exporter, exportStatus } = useGetHandlerExport({
    retrieveSelected: true,
    type: "not-processed",
  });

  const handleExport = () => {
    exporter(tableRef.current ?? undefined);
  };

  const [showModal, setShowModal] = useState(false);
  const [renderMatchPayments, setRenderMatchPayments] = useState(false);

  const handleCloseModalForm = () => setShowModal(false);

  const handleMoveToNotMatched = async () => {
    if (
      tableRef.current &&
      tableRef.current?.getSelectedRowModel().rows.length > 0
    ) {
      await updateStatusPayment({
        payments: tableRef.current?.getSelectedRowModel().rows.map((row) => {
          return {
            id: row.original.id,
            status: "not_matched",
          };
        }),
        changeToSingleStatus: true,
      });

      tableRef.current?.resetRowSelection();
    }

    setShowModal(false);
  };

  const [checkedRow, setCheckedRow] = useState(false);
  const [selectedPayments, setSelectedPayments] = useState<
    Array<Row<PaymentColumns>>
  >([]);

  const handleChangeCheckedRow = useCallback(() => {
    setCheckedRow((prev) => {
      return !tableRef.current?.getIsAllRowsSelected() || !prev;
    });
  }, []);

  const notProcessedPaymentsColumns = [
    columnHelper.accessor("checkbox", {
      enableSorting: false,
      header: ({ table }) => {
        return (
          <input
            className={styles.checkboxSelectRow}
            type="checkbox"
            checked={table.getIsAllRowsSelected()}
            onChange={(e) => {
              handleChangeCheckedRow();
              table.getToggleAllRowsSelectedHandler()(e);
            }}
          />
        );
      },
      cell: ({ row }) => {
        return (
          <div className={styles.centerCheckbox}>
            <input
              className={styles.checkboxSelectRow}
              type="checkbox"
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              onChange={(e) => {
                handleChangeCheckedRow();
                row.getToggleSelectedHandler()(e);
              }}
            />
          </div>
        );
      },
      meta: {
        headerWidth: "50px",
        removeSortIcon: true,
      },
    }),
    columnHelper.accessor("customerId", {
      header: t("pages.payments.columnHeaders.customerAndId"),
      cell: (info) => {
        return (
          <div className={styles.alignContentToLeft}>
            <p>
              {info.cell.row.original.customerName ||
                t("pages.payments.missingValue")}
            </p>
            {info.getValue() && (
              <Pill
                text={`${info.getValue()}`}
                href={`/customer/${info.getValue()}`}
                intent="Link"
              />
            )}
          </div>
        );
      },
      meta: {
        headerIcon: "usercirclesingle",
      },
    }),
    columnHelper.accessor("customerName", {
      meta: {
        hidden: true
      }
    }),
    columnHelper.accessor("inioInvoiceNo", {
      header: t("pages.payments.columnHeaders.caseId"),
      cell: (info) =>
        info.getValue() ? (
          <div className={styles.alignContentToLeft}>
            <Pill
              text={`${info.getValue()}`}
              href={`/cases/${info.getValue()}`}
              intent="Link"
            />
          </div>
        ) : (
          t("pages.payments.missingValue")
        ),
      meta: {
        headerIcon: "streamlineCoreLineFileText",
      },
    }),
    columnHelper.accessor("status", {
      header: t("pages.payments.columnHeaders.status"),
      cell: (info) => (
        <div className={styles.statusBadgesContainer}>
          <StatusBadge
            key={info.getValue()}
            status={
              info.getValue().split("_").join(" ") as PaymentColumns["status"]
            }
          />
          {info.cell.row.original.statusReason && (
            <StatusBadge
              key={info.cell.row.original.statusReason}
              status={
                info.cell.row.original.statusReason
                  .split("_")
                  .join(" ") as PaymentColumns["statusReason"]
              }
            />
          )}
        </div>
      ),
      meta: {
        headerIcon: "streamLineFileCheck",
      },
    }),
    columnHelper.accessor("amount", {
      header: t("pages.payments.columnHeaders.amount"),
      cell: (info) => {
        return formatCurrencyWithNumberFormat(
          info.getValue(),
          language,
          info.cell.row.original.currency,
        );
      },
      meta: {
        filterType: "text",
        headerIcon: "coreLineMoney",
      },
    }),
    columnHelper.accessor("paidDate", {
      header: t("pages.payments.columnHeaders.paymentDate"),
      cell: (info) => {
        return `${new Intl.DateTimeFormat(
          countryCodeMapper[language as keyof typeof countryCodeMapper],
          {
            month: "long",
            day: "numeric",
            year: "numeric",
          },
        ).format(new Date(info.getValue()))}`;
      },
      meta: {
        headerIcon: "blankcalendar",
        filterType: "date",
      },
      filterFn: (row, columnId, filterValue) => {
        const date = new Date(row.getValue(columnId));
        const [start, end] = filterValue || [];
        return (
          (!start || date >= new Date(start)) && (!end || date <= new Date(end))
        );
      },
    }),
    columnHelper.accessor("transactionReference", {
      header: t("pages.payments.columnHeaders.transactionReference"),
      cell: (info) => <Truncate value={info.getValue() as string} />,
      meta: {
        headerIcon: "streamlineCoreLineFileText",
      },
    }),
    columnHelper.accessor("paymentReference", {
      header: t("pages.payments.columnHeaders.paymentReference"),
      cell: (info) =>
        info.getValue() ? (
          <>
            {splitWordOnNewLine(info.getValue() as string, 20).map((el) => (
              <p key={el}>{el}</p>
            ))}
          </>
        ) : (
          t("pages.payments.missingValue")
        ),
      meta: {
        headerIcon: "streamlineCoreLineFileText",
      },
    }),
    columnHelper.accessor("transactionType", {
      header: t("pages.payments.columnHeaders.paymentMethod"),
      cell: (info) => info.getValue(),
      meta: {
        headerIcon: "bank",
        filterType: "checkboxes",
        filterOptions: [
          t("pages.payments.bankTransfer"),
          t("pages.payments.card"),
          t("pages.payments.swish"),
        ],
      },
      filterFn: (row, columnId, filterValue) => {
        const paymentMethod = row.getValue(columnId) as string;

        if (filterValue.length > 0) {
          return filterValue.includes(paymentMethod);
        }
      },
    }),
  ];

  return (
    <>
      {renderMatchPayments ? (
        <MatchPaymentsDetails
          selected={selectedPayments}
          onHideDetails={() => setRenderMatchPayments(false)}
          moveToMatchedPaymentsTab={() => {
            moveToMatchedPaymentsTab();
          }}
        />
      ) : (
        <Table
          ref={tableRef}
          data={data?.rows}
          columns={notProcessedPaymentsColumns}
          header={true}
          filters={true}
          searchPlaceholder={t("pages.payments.searchPlaceholder")}
          isLoading={isLoading}
          headerCta={
            <Button
              intent="black"
              text={t("pages.payments.export")}
              icon={{ name: "arrowup", intent: "white" }}
              iconPosition="right"
              className={styles.exportButton}
              onClick={handleExport}
              disabled={exportStatus.loading === "loading" || !checkedRow}
            />
          }
          onSelectedRow={(areRowsSelected) => {
            if (tableRef.current) {
              setCheckedRow(areRowsSelected);
            }
          }}
          noDataAvailableText={t("pages.payments.notProcessedPayments")}
        >
          <div className={styles.bottomTableButtonsWrapper}>
            <Button
              text={t("pages.payments.moveToNotMatched")}
              disabled={!checkedRow}
              intent="primary-light"
              outline={false}
              onClick={() => {
                setShowModal(true);
              }}
              className={styles.bottomTableButtonLight}
            />
            <Button
              text={t("pages.payments.matchPayments")}
              disabled={!checkedRow}
              intent="black"
              outline={false}
              onClick={() => {
                setRenderMatchPayments(true);
                setSelectedPayments(
                  tableRef.current
                    ? tableRef.current?.getSelectedRowModel().rows
                    : [],
                );
              }}
              className={styles.bottomTableButtonDark}
            />
          </div>
        </Table>
      )}

      {exportStatus.error && "Error"}

      {/* Move to not matched modal */}
      <ModalAction
        direction="column"
        isOpen={showModal}
        onClose={handleCloseModalForm}
        intent="danger"
        title={t("pages.customer.attention")}
        content={() => (
          <div>{t("pages.payments.areYouSureToMovePaymentsToNotMatched")}</div>
        )}
        ctaBtns={[
          {
            text: t("close"),
            intent: "primary-light",
            onClick: handleCloseModalForm,
            disabled: isChangeStatusLoading,
          },
          {
            text: "Yes",
            onClick: handleMoveToNotMatched,
            intent: "black",
            disabled: isChangeStatusLoading,
          },
        ]}
      />
    </>
  );
}
