import {
  CoreRow,
  flexRender,
  Table as ReactTable,
} from "@tanstack/react-table";
import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "components/Icon";
import { NoResults } from "components/NoResults";
import { Skeleton } from "components/Skeleton";

import { classNames } from "lib/classNames";
import { usePagination } from "lib/hooks/usePagination";

import styles from "./Table.module.scss";
import { TablePagination } from "./TablePagination";

type TableBodyProps<TData> = {
  table: ReactTable<TData>;
  MobileElement?: ({ rowData }: { rowData: any[] }) => JSX.Element;
  children?: ReactNode;
  selectedRows?: CoreRow<TData>[];
  borderedTable?: boolean;
  isLoading?: boolean;
  totalPages?: number;
  noDataAvailableText?: string;
};

export function TableBody<TData>({
  table,
  MobileElement,
  children,
  selectedRows = [],
  borderedTable = false,
  isLoading,
  totalPages,
  noDataAvailableText,
}: TableBodyProps<TData>) {
  const pagesToRender = usePagination(table, 5, 4, totalPages, isLoading);
  const { t } = useTranslation();

  const getVisibleColumnsLength = useMemo(() => {
    return table
      .getVisibleLeafColumns()
      .filter((column) => !column.columnDef.meta?.hidden).length;
  }, [table]);

  if (isLoading) {
    return (
      <div className={styles.scrollableTable}>
        <table className={borderedTable ? styles.bordered : ""}>
          <thead>
            <tr>
              {Array.from({ length: 5 }).map((_, i) => (
                <th key={i}></th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 5 }).map((_, index) => (
              <tr key={index}>
                {Array.from({ length: 5 }).map((_, index) => (
                  <td key={index}>
                    <Skeleton width="100%" height="20px" />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <>
      {MobileElement && (
        <div className={styles.tableContainerMobile}>
          {table.getRowModel().rows.map((row) => (
            <MobileElement key={row.id} rowData={row.getVisibleCells()} />
          ))}
        </div>
      )}
      <div className={styles.scrollableTable}>
        <table className={borderedTable ? styles.bordered : ""}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers
                  .filter((column) => !column.column.columnDef.meta?.hidden)
                  .map((header) => (
                    <th
                      key={header.id}
                      style={{
                        width: `${header.column.columnDef.meta?.headerWidth || header.getSize() + "px"}`,
                        cursor: header.column.getCanSort()
                          ? "pointer"
                          : undefined,
                      }}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div
                        style={{
                          textWrap: header.column.columnDef.meta?.wrapHeader
                            ? "wrap"
                            : "nowrap",
                        }}
                        className={classNames(styles.headerColumn, {
                          [styles.centerCellContent]:
                            !!header.column.columnDef.meta?.removeSortIcon,
                        })}
                      >
                        {header.column.columnDef.meta?.headerIcon && (
                          <Icon
                            name={header.column.columnDef.meta?.headerIcon}
                            size={16}
                            color="#4A4A4A"
                          />
                        )}

                        {!header.isPlaceholder &&
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}

                        {!header.column.columnDef.meta?.removeSortIcon &&
                          header.column.getCanSort() && (
                            <div className={styles.sortingIndicator}>
                              {{
                                asc: (
                                  <Icon
                                    name="chevronUp"
                                    size={12}
                                    color="#4a4a4a"
                                  />
                                ),
                                desc: (
                                  <Icon
                                    name="chevronDown"
                                    size={12}
                                    color="#4a4a4a"
                                  />
                                ),
                              }[header.column.getIsSorted() as string] ?? (
                                <p>&#8212;</p>
                              )}
                            </div>
                          )}
                      </div>
                    </th>
                  ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {/* If there is no data available */}
            {table.getRowModel().rows.length === 0 ? (
              <tr>
                <td colSpan={getVisibleColumnsLength}>
                  <NoResults
                    text={noDataAvailableText || t("noDataAvailable")}
                  />
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row
                    .getVisibleCells()
                    .filter((column) => !column.column.columnDef.meta?.hidden)
                    .map((cell) => (
                      <td
                        key={cell.id}
                        className={classNames({
                          [styles.selectedRow]: selectedRows.includes(row),
                        })}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <TablePagination table={table} pagesToRender={pagesToRender}>
        {children}
      </TablePagination>
    </>
  );
}
