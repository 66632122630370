import { Row } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PaymentColumns } from "types";

import { Button } from "components/Button";

import { classNames } from "lib/classNames";

import styles from "./MatchPaymentsDetails.module.scss";
import { MatchPaymentsDetailsInfoCard } from "./MatchPaymentsDetailsInfoCard";
import { MatchpaymentsDetailsPagination } from "./MatchPaymentsDetailsPagination";
import { formatCurrencyWithNumberFormat } from "lib/helpers";
import { useSelector } from "react-redux";
import { IState } from "app/store";
import { SuggestedInvoicesToMatch } from "./SuggestedInvoicesToMatch";
import { Card } from "components/Card/Card";

type Props = {
  onHideDetails: () => void;
  selected: Array<Row<PaymentColumns>>;
  moveToMatchedPaymentsTab: () => void;
};

export function MatchPaymentsDetails({
  onHideDetails,
  selected,
  moveToMatchedPaymentsTab
}: Props) {
  const { t } = useTranslation();
  const language = useSelector((state: IState) => state.language.language);
 
  const [selectedRowRemapping, setSelectedRowRemapping] = useState(selected?.map((el) => {
    return {
      customerName: el.original.customerName,
      paymentReference: el.original.paymentReference,
      amount: formatCurrencyWithNumberFormat(el.original.amount, language, el.original.currency),
      paymentDate: el.original.paidDate,
      paymentMethod: el.original.transactionType + " | " + el.original.transactionReference,
      address: el.original.address,
      phoneNumber: el.original.phoneNumber,
      otherText: el.original.message,
      id: el.original.id,
      status: el.original.status,
      statusReason: el.original.statusReason,
    };
  }));

  const [currentRow, setCurrentRow] = useState(0);

  useEffect(() => {
    if(selectedRowRemapping.length === 0) {
      onHideDetails();
    }
  }, [selectedRowRemapping, onHideDetails]);

  const removeSelectedRow = (currentRow: number, triggerDeselecting = true) => {
    if(triggerDeselecting) {
      setSelectedRowRemapping(prev => prev.filter(el => el.id !== selectedRowRemapping[currentRow].id));
      moveToMatchedPaymentsTab();
    }
  };

  return (
    <Card>
      <section className={styles.matchPaymentsDetails}>
        <div className={styles.matchPaymentsInfo}>
          <header
            className={classNames(
              styles.matchPaymentsInfoHeader,
              styles.matchPaymentsColumnHeader,
            )}
            onClick={onHideDetails}
          >
            <Button
              className={styles.matchPaymentsInfoHeaderIcon}
              icon={{
                name: "chevronLeft",
                size: 14,
              }}
            />
            <span>{t("pages.payments.notProcessedTab")}</span>
          </header>
          <section className={styles.matchPaymentsInfoCard}>
            {selectedRowRemapping && selectedRowRemapping[currentRow] && (
              <MatchPaymentsDetailsInfoCard row={selectedRowRemapping[currentRow]} onRemoveSelection={removeSelectedRow.bind(null, currentRow)} />
            )}
          </section>

          {selectedRowRemapping && selectedRowRemapping.length > 1 && (
            <MatchpaymentsDetailsPagination
              current={currentRow}
              all={selectedRowRemapping.length}
              onChangeIndex={(ind) => setCurrentRow(ind)}
            />
          )}
        </div>
        <SuggestedInvoicesToMatch key={selectedRowRemapping.length} paymentId={selectedRowRemapping[currentRow]?.id} moveToMatchedPaymentsTab={() => {
          removeSelectedRow(currentRow);
          if(selectedRowRemapping.length === 1) {
            moveToMatchedPaymentsTab();
          }
        }} />
      </section>
    </Card>
  );
}
