import { useAuth } from "react-oidc-context";

import { SupportedRoles } from "types";

type Props = {
  role: SupportedRoles;
  children: React.ReactNode | React.ReactNode[];
};

export function AuthRoleCheck({ role, children }: Props) {
  const { user } = useAuth();

  if (!user) {
    return <></>;
  }

  if (user?.profile.roles.includes(role)) {
    return children;
  }

  return <></>;
}
