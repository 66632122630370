import { ReactNode } from "react";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";

import { RInioButton } from "ui-core";

type Props = {
  children: ReactNode;
};

function PrivateRoute({ children }: Props) {
  const { user, signoutRedirect } = useAuth();

  if (
    !user?.profile.roles.includes("client-operator") &&
    !user?.profile.clientId
  ) {
    return (
      <div>
        Your account <strong>{user?.profile.email}</strong> is not associated
        with any groups. This has happened likely due to a misconfiguration,
        please contact your administrator.
        <br />
        <br />
        Once you have been added, please sign out and login again.
        <br />
        <br />
        <div style={{ maxWidth: 180 }}>
          <RInioButton text="Sign out" onClick={signoutRedirect} />
        </div>
      </div>
    );
  }

  return children;
}

export default withAuthenticationRequired(PrivateRoute, {
  OnRedirecting: () => {
    const location = window.location;
    const currentPath = location.pathname + location.search;
    sessionStorage.setItem("previousPath", currentPath);

    return <div>Redirecting to the login page...</div>;
  }
});
