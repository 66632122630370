import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import { DashboardHeader } from "components/DashboardHeader";
import { Tabs } from "components/Tabs";

import styles from "./PagePayments.module.scss";
import { AllPayments } from "./tabs/AllAndMatchedPayments/AllPayments";
import { NotProcessedPayments } from "./tabs/NotProcessedPayments/NotProcessedPayments";
import { NotMatchedPayments } from "./tabs/NotMatchedPayments/NotMatchedPayments";
import { useState } from "react";

export function PagePayments() {
  const { t } = useTranslation();
  const auth = useAuth();

  const [manualTabFocusIndex, setManualTabFocusIndex] = useState<number | null>(0);

  const handleChangeTabIndex = (newIndex: number) => {
    setManualTabFocusIndex(newIndex);
  };

  return (
    <>
      <main className={styles.main}>
        {manualTabFocusIndex}
        <DashboardHeader
          title={t("pages.payments.header")}
          subHeader={
            auth.user?.profile.clientName
              ? t("pages.payments.subHeader", {
                  clientName: auth.user?.profile.clientName,
                })
              : t("pages.payments.subHeaderInioOperator")
          }
        />

        <section className={styles.tabsSection}>
          <Tabs
            manualTabFocusIndex={manualTabFocusIndex}
            onChange={() => setManualTabFocusIndex(null)}
            tabs={[
              {
                title: t("pages.payments.notProcessedTab"),
                content: <NotProcessedPayments moveToMatchedPaymentsTab={handleChangeTabIndex.bind(null, 0)} />
              },
              {
                title: t("pages.payments.notMatchedTab"),
                content: <NotMatchedPayments />,
              },
              {
                title: t("pages.payments.matchedTab"),
                content: <AllPayments type="matched" />,
              },
              {
                title: t("pages.payments.allTab"),
                content: <AllPayments type="all" />,
              }
            ]}
            btnMinWidth={190}
          />
        </section>
      </main>
    </>
  );
}
