import { CoreHeader } from "@tanstack/react-table";
import { ChangeEvent, useMemo } from "react";

import { RInioInput } from "ui-core";
import { RInioInputSelect } from "ui-core/components/InputSelect/InputSelect";

import { Filters } from "components/Filters";
import { DateWidget } from "components/Form/DateWidget";
import { FieldTemplate } from "components/Form/FieldTemplate";
import { Range } from "components/Range";

type Props<TData> = {
  column: CoreHeader<TData, unknown>["column"];
};

export function TableFilter<TData>({ column }: Props<TData>) {
  const label = column.columnDef.header as string;
  const filterType = column.columnDef.meta?.filterType;
  const filterOptions = column.columnDef.meta?.filterOptions;

  const sortedUniqueValues = useMemo(() => {
    const columns = column.getFacetedUniqueValues()?.keys();
    return columns ? Array.from(columns).sort() : [];
  }, [column]);

  switch (filterType) {
    case "checkboxes":
      return (
        <Filters
          title={label || column.id}
          value={column.getFilterValue() as string}
          onChange={(value) => {
            column.setFilterValue(value.length === 0 ? undefined : value);
          }}
          filters={
            filterOptions
              ? filterOptions.map((f) => ({ value: f }))
              : Array.from(column.getFacetedUniqueValues().keys())
                  .sort()
                  .map((f) => ({ value: f }))
          }
        />
      );
    case "text":
      return (
        <RInioInput
          label={label}
          placeholder="Search..."
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            column.setFilterValue(e.target.value?.toLowerCase());
          }}
          value={(column.getFilterValue() as string) ?? ""}
        />
      );
    case "dropdown":
      return (
        <RInioInputSelect
          label={label}
          onChange={(value: string) => {
            column.setFilterValue(value);
          }}
          // value={(column.getFilterValue() as string[]) ?? ""}
          options={sortedUniqueValues.map((f) => ({
            value: f,
            label: f,
          }))}
        />
      );
    case "range":
      return (
        // @ts-expect-error not all props
        <FieldTemplate label={label}>
          <Range
            min={column.getFacetedMinMaxValues()?.[0]}
            max={column.getFacetedMinMaxValues()?.[1]}
            value={
              (column.getFilterValue() as [number, number])?.[1] ??
              column.getFacetedMinMaxValues()?.[1]
            }
            onChange={({ target }: any) =>
              column.setFilterValue([0, parseInt(target.value, 10)])
            }
          />
        </FieldTemplate>
      );
    case "date":
      return (
        // @ts-expect-error not all props
        <FieldTemplate label={label}>
          <DateWidget
            value={column.getFilterValue() as [Date, Date]}
            onChange={(value) =>
              !value.startDate && !value.endDate
                ? column.setFilterValue(undefined)
                : column.setFilterValue([value.startDate, value.endDate])
            }
            options={{
              fillSpace: true,
              buttonsLayout: ["select-today", "cancel"],
            }}
            range={true}
          />
        </FieldTemplate>
      );
    default:
      return null;
  }
}
