import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  TableOptions,
  SortingState,
  ColumnFiltersState,
  ColumnDef,
  CoreRow,
  PaginationState,
  Table as TTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { ModalAction } from "components/Modal";

import { inDateRange } from "lib/dateUtils";
import {
  QUERY_KEYS,
  resolveState,
  updateUrlWithParams,
} from "lib/filtersToURL";

import styles from "./Table.module.scss";
import { TableBody } from "./TableBody";
import { TableTop } from "./TableTop";

interface TableProps<TData> {
  data: TData[] | undefined;
  isLoading?: boolean;
  columns: ColumnDef<any, any>[];
  filters?: any;
  header?: boolean;
  headerCta?: React.ReactNode;
  mobileElement?: ({ rowData }: { rowData: any[] }) => JSX.Element;
  children?: React.ReactNode;
  CustomTable?: (props: { table: any }) => JSX.Element;
  pageSize?: number;
  setData?: (data: React.SetStateAction<TData[]>) => void;
  selectedRows?: CoreRow<TData>[];
  deleteRows?: boolean;
  onRowDelete?: (rows: CoreRow<TData>[]) => void;
  searchPlaceholder?: string;
  borderedTable?: boolean;
  onSelectedRow?: (selected: boolean) => void;
  noDataAvailableText?: string;
}

export const Table = forwardRef(function Table<TData>(
  {
    data = [],
    columns,
    filters,
    header,
    headerCta,
    mobileElement,
    children,
    CustomTable,
    pageSize = 10,
    setData,
    selectedRows = [],
    deleteRows,
    onRowDelete,
    searchPlaceholder,
    borderedTable,
    isLoading,
    onSelectedRow,
    noDataAvailableText
  }: TableProps<TData>,
  ref: Ref<TTable<TData>>,
) {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState<string | undefined>();
  const [showDeleteModal, setDeleteModalOpen] = useState(false);
  const [deleteUserConfirmation, setDeletedUserConfirmation] = useState({
    visibility: false,
    deletedUsers: [] as CoreRow<TData>[],
  });
  const pageIndex =
    parseInt(
      new URLSearchParams(window.location.search).get(QUERY_KEYS.pagination) ??
        "0",
    ) - 1;
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: pageIndex === -1 ? 0 : pageIndex,
    pageSize,
  });

  // On mount, read URL params to initialize filters, sorting, pagination
  useEffect(() => {
    const params = window.location.search;
    const queryParams = new URLSearchParams(params);

    if (queryParams.has(QUERY_KEYS.sort)) {
      setSorting([
        {
          id: queryParams.get(QUERY_KEYS.sort) as string,
          desc:
            queryParams.get(QUERY_KEYS.order) === QUERY_KEYS.asc ? false : true,
        },
      ]);
    }

    const columnFilters: ColumnFiltersState = [];
    queryParams.forEach((value, key) => {
      if (
        // @ts-expect-error key 'string' is not assignable to parameter of type 'QUERY_KEYS'
        [QUERY_KEYS.pagination, QUERY_KEYS.sort, QUERY_KEYS.order].includes(key)
      ) {
        return;
      }

      columnFilters.push({
        id: key,
        value: value.includes(",") ? value.split(",") : value,
      });
    });

    setColumnFilters(columnFilters);
  }, []);

  const table = useReactTable({
    data: data ?? [],
    columns,
    filterFns: {
      inDateRange,
    },
    initialState: {
      pagination,
    },
    state: {
      globalFilter,
      columnFilters,
      sorting,
      pagination,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: (sortState) => {
      setSorting(sortState);
      const resolvedSorting = resolveState(sortState, sorting);
      if (resolvedSorting[0]) {
        updateUrlWithParams(
          QUERY_KEYS.sort,
          resolvedSorting[0].id.replace("_", "."),
        );
        updateUrlWithParams(
          QUERY_KEYS.order,
          resolvedSorting[0].desc ? QUERY_KEYS.desc : QUERY_KEYS.asc,
        );
      } else {
        updateUrlWithParams(QUERY_KEYS.sort, undefined);
        updateUrlWithParams(QUERY_KEYS.order, undefined);
      }
    },
    onColumnFiltersChange: (filters) => {
      const resolvedFilters = resolveState(filters, columnFilters);
      setColumnFilters((oFilters) => {
        // remove old filters from URL
        oFilters.forEach((oF) => updateUrlWithParams(oF.id, undefined));
        // add new filters
        resolvedFilters.forEach((f) =>
          updateUrlWithParams(f.id.replace("_", "."), f.value as string),
        );
        return resolvedFilters;
      });
      setPagination({ pageIndex: 0, pageSize: 10 });
    },
    onPaginationChange: (newPagination) => {
      const resolvedPagination = resolveState(newPagination, pagination);
      updateUrlWithParams(
        QUERY_KEYS.pagination,
        (resolvedPagination.pageIndex + 1).toString(),
      );
      setPagination(newPagination);
    },
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    meta: {
      resetRow: (rowIndex: number, rowData: any) => {
        setData?.((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return rowData;
            }
            return row;
          }),
        );
      },
    },
  } as TableOptions<TData>);

  useEffect(() => {
    if (!table.getIsAllRowsSelected()) {
      onSelectedRow?.(table.getIsSomePageRowsSelected());
    }
  }, [table, onSelectedRow]);

  useImperativeHandle(ref, () => {
    return table;
  });

  if (CustomTable) {
    return <CustomTable table={table} />;
  }

  const headers = useMemo(() => {
    columns
      .map((column) => column.header)
      .filter(Boolean)
      .join(", ");
  }, [columns]);

  return (
    <>
      <div className={styles.tableContainer}>
        <div className={styles.tableMain}>
          <div>
            {header && (
              <TableTop
                table={table}
                ctaBtn={headerCta}
                filters={filters}
                deleteRows={deleteRows}
                selectedRows={selectedRows}
                searchPlaceholder={
                  searchPlaceholder ?? t("table.searchPlaceholder", { headers })
                }
                setDeleteModalOpen={setDeleteModalOpen}
                isLoading={isLoading}
              />
            )}
            <TableBody
              table={table}
              MobileElement={mobileElement}
              selectedRows={selectedRows}
              borderedTable={borderedTable}
              isLoading={isLoading}
              noDataAvailableText={noDataAvailableText}
            >
              {children}
            </TableBody>
          </div>
        </div>
      </div>

      <ModalAction
        title="Attention"
        direction="column"
        content={() => {
          return (
            <>
              <p>
                Are you sure you want to delete{" "}
                {selectedRows.map((row, i, arr) => {
                  return (
                    <span key={row.id} style={{ display: "block" }}>
                      {/* TODO: Fix me to be dynamic */}
                      {/* @ts-expect-error type error */}
                      <strong>{`${row.original.firstName} ${row.original.lastName} - ${row.original.clientName} - ${row.original.role}`}</strong>
                      {i === arr.length - 1
                        ? "?"
                        : i === arr.length - 2
                          ? " and "
                          : ", "}
                    </span>
                  );
                })}
              </p>
            </>
          );
        }}
        isOpen={showDeleteModal ? true : false}
        intent="danger"
        ctaBtns={[
          { text: t("close"), onClick: () => setDeleteModalOpen(false) },
          {
            text: t("Delete User(s)"),
            onClick: () => {
              onRowDelete?.(selectedRows);
              setDeleteModalOpen(false);
              setDeletedUserConfirmation({
                visibility: true,
                deletedUsers: selectedRows,
              });
            },
            intent: "danger",
          },
        ]}
        onClose={() => setDeleteModalOpen(false)}
      />

      <ModalAction
        title=""
        direction="column"
        content={() => {
          return (
            <>
              <p>
                User
                {deleteUserConfirmation.deletedUsers.length > 1 ? "s " : " "}
                {deleteUserConfirmation.deletedUsers.map((row, i, arr) => {
                  return (
                    <strong
                      key={row.id}
                      style={{
                        display: i < arr.length - 2 ? "block" : "initial",
                      }}
                    >
                      {/* TODO: Fix me to be dynamic */}
                      {/* @ts-expect-error type error */}
                      {`${row.original.firstName} ${row.original.lastName} - ${row.original.clientName} - ${row.original.role}`}
                    </strong>
                  );
                })}{" "}
                are deleted.
              </p>
            </>
          );
        }}
        isOpen={deleteUserConfirmation.visibility}
        intent="info"
        ctaBtns={[
          {
            text: t("close"),
            onClick: () =>
              setDeletedUserConfirmation({
                visibility: false,
                deletedUsers: [],
              }),
          },
        ]}
        onClose={() =>
          setDeletedUserConfirmation({
            visibility: false,
            deletedUsers: [],
          })
        }
      />
    </>
  );
});
